import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

const Related = ({ data }) => {
  return (
    <Layout>
      <div className="text-page">
        <h2 className="has-no-margin">Gerelateerde links</h2>

        <ul>
          {data.allLinksCsv.edges.map(({ node }) => (
            <li className="has-margin-bottom-small" key={node.title}>
              <a href={node.link} target="_blank" rel="noopener noreferrer">
                {node.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export default Related

export const query = graphql`
  query Gerelateerd {
    allLinksCsv {
      edges {
        node {
          title
          link
        }
      }
    }
  }
`
